function App() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <h1>Welcome to Upward Insurance</h1>
      <ul>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
        <li>
          <a href="/umis/login">Login to umis</a>
        </li>
        <li>
          <a href="/ucsmi/login">Login to ucsmi</a>
        </li>
      </ul>
    </div>
  );
}

export default App;
